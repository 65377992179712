import { widgetScriptBuilder } from "@wix/bob-widget-services";
import {
  WixSelector,
  WidgetCreatorParams,
} from "@wix/bob-widget-services/dist/src/types/viewerTypes";
import { ControllerFlowAPI } from "@wix/yoshi-flow-editor-runtime/build/cjs/flow-api/ViewerScript";
import { IServerErrorResponse, ISubmitProps, SocialAuthProps } from "./types";
import { INTERACTIONS, serverErrorsHandler } from "../../utils/viewer";
import { i18nextCore } from "../../utils/i18n";
import { TFunction } from "i18next";
import {
  validateSiteMembersEmail,
  validateSiteMembersPassword,
} from "../../utils/validations";
import initSchemaLogger from "@wix/bi-logger-site-members";

const validator = (
  validatorFun: (val: string, t: TFunction) => string | null,
  t: TFunction
) => (val: string, reject: (error: string) => void) => {
  const error = validatorFun(val, t);
  if (error) {
    reject(error);
  }
};

export const submit = (props: ISubmitProps) => async (
  event: any
): Promise<void> => {
  const {
    $w,
    authentication,
    users,
    recaptchaToken,
    sendBIOnFail,
    sendBIOnSubmit,
    sendBIOnSuccess,
    t,
  } = props;
  if ($w("@generalErrMsg").length) {
    $w("@generalErrMsg").hide();
  }
  const email = $w("@email");
  const password = $w("@password");
  if (!email.valid || !password.valid) {
    return;
  }
  try {
    sendBIOnSubmit();
    await users.login(email.value, password.value, {recaptchaToken});
    sendBIOnSuccess();
  } catch (err) {
    const errorMsg = serverErrorsHandler(err as IServerErrorResponse);
    // In case the server require a captcha we popping up the challenge
    const shouldPresentACaptcha = errorMsg.includes('19971') || errorMsg.includes('19970');
    if (shouldPresentACaptcha) {
      props.recaptchaToken = await authentication.openCaptchaChallenge();
      return submit(props)(event);
    }
    if (!$w("@generalErrMsg").length) {
      return;
    }
    $w("@generalErrMsg").text = t(errorMsg);
    $w("@generalErrMsg").show();
    sendBIOnFail();
  }
};

export const fieldChanged = ($w: WixSelector) => (event: any) => {
  const field = event.target;
  const error = $w(`@${field.id}ErrMsg`)[0];
  // The reason for using `setTimeout` is that this callback being
  // called before the input perform his validation so the
  // validation props are not up to date
  setTimeout(() => {
    if (!field.valid) {
      error.text = field.validationMessage;
    }
    field.resetValidityIndication();
    error.hide();
  });
};

const socialAuth = ({user, window, fedops}: SocialAuthProps) => async (
  event: any
) => {
  let data;
  try {
    data = JSON.parse(event.data);
  } catch (e) {
    return;
  }

  if (data.id === "wix-social-login") {
    switch (data.type) {
      // Google auth flow
      case "auth-done":
        let eventData;
        try {
          eventData = JSON.parse(data.data);
        } catch (e) {
          console.error("Couldn't parse the social iframe message: ", e);
          return;
        }
        fedops.interactionStarted(INTERACTIONS.CLICK_SOCIAL)
        await user.applySessionToken(eventData.smSession.sessionToken);
        window.lightbox.close();
        fedops.interactionEnded(INTERACTIONS.CLICK_SOCIAL)
        break;
      // Facebook auth flow
      case "auth-token":
        fedops.interactionStarted(INTERACTIONS.CLICK_SOCIAL)
        await user.handleOauthToken(data.token, data.vendor, "login");
        window.lightbox.close();
        fedops.interactionEnded(INTERACTIONS.CLICK_SOCIAL)
        break;
      default:
        break;
    }
  }
};

function isSocialInAppBrowser() {
  const socialInAppBrowserIdentifiers = ["FBAV", "FBAN", "Instagram"];

  return socialInAppBrowserIdentifiers.findIndex(ua => navigator?.userAgent?.includes(ua)) !== -1;
}

// TODO: if this method breaks we get no error in the console, we might want to log it using Sentry
// TODO: Once we done here we better replace the controller prop with destructuring for readability
function createWidget(
  controller: WidgetCreatorParams,
  flowAPI: ControllerFlowAPI
) {
  return {
    pageReady: async () => {
      const $w = controller.$w;
      const experiments = await flowAPI.getExperiments();
      const unsupportedSocialAuthMessageExperiment = await experiments.conduct('specs.thunderbolt.sm_customLoginSocialAuthMessageInInAppBrowser');

      if (
        unsupportedSocialAuthMessageExperiment === 'new' &&
        $w("@socialDividerLabel") &&
        isSocialInAppBrowser()) {
        $w("@socialDividerLabel").hide();
      }

      // TODO: this is a work around for https://jira.wixpress.com/browse/PLAT-1170%D6%BF we are waiting for the viewer to hide close buttons in case of protected pages
      if ($w("@generalErrMsg").length) {
        $w("@generalErrMsg").show();
        $w("@generalErrMsg").hide();
      }
      const loggerFactory = controller.platformAPIs.biLoggerFactory();
      const logger = initSchemaLogger(loggerFactory)();
      logger.siteMembersLoginCustomLoginLoad({
        visitor_id: controller.platformAPIs.bi.visitorId,
      });
      const sendBIOnSuccess = () => {
        flowAPI.fedopsLogger.interactionEnded(INTERACTIONS.LOGIN_SUBMIT)
        return logger.siteMembersLoginSucesss({
          visitor_id: controller.platformAPIs.bi.visitorId,
          site_member_id: controller.platformAPIs.bi.siteMemberId,
          provider_id: "wix",
          form_type: "custom",
        });
      }
      const sendBIOnFail = () =>
        logger.siteMembersLoginFail({
          visitor_id: controller.platformAPIs.bi.visitorId,
          provider_id: "wix",
          form_type: "custom",
        });
      const sendBIOnSubmit = () => {
        flowAPI.fedopsLogger.interactionStarted(INTERACTIONS.LOGIN_SUBMIT)
        return logger.siteMembersClickOnGoFromSignUpLoginPassWord({
          visitor_id: controller.platformAPIs.bi.visitorId,
          form_type: "custom",
          biToken: controller.platformAPIs.bi.metaSiteId,
          viewmode: controller.platformAPIs.bi.viewMode,
        });
      }
      const url = controller.appParams.url.replace(
        "/viewerScript.bundle.min.js",
        ""
      );
      const t = await i18nextCore(controller.wixCodeApi.site.language, url);
      if ($w("@closeIcon")[0]) {
        $w('@closeIcon').onClick(() => {
          flowAPI.fedopsLogger.interactionStarted(INTERACTIONS.CLOSING_WINDOW)
          flowAPI.fedopsLogger.interactionEnded(INTERACTIONS.CLOSING_WINDOW)
        })
      }
      if ($w("@submit")[0]) {
        $w("@submit")[0].onClick(
          submit({
            $w,
            users: controller.wixCodeApi.user,
            authentication: controller.wixCodeApi.authentication,
            t,
            sendBIOnSubmit,
            sendBIOnSuccess,
            sendBIOnFail,
          })
        );
      }
      const passwordField = $w("@password")[0];
      const emailField = $w("@email")[0];
      if (passwordField) {
        passwordField.onCustomValidation(
          validator(validateSiteMembersPassword, t)
        );
      }
      if (emailField) {
        emailField.onCustomValidation(validator(validateSiteMembersEmail, t));
      }
      // Register to social iframe messages
      if ($w("@socialIframe")[0]) {
        controller
          .$w("@socialIframe")[0]
          .onMessage(
            socialAuth({
              user: controller.wixCodeApi.user,
              window: controller.wixCodeApi.window,
              fedops: flowAPI.fedopsLogger
            })
          );
      }
      const signUpLink = $w("@signUpLink")[0];
      if (signUpLink) {
        signUpLink.onClick(() => {
          flowAPI.fedopsLogger.interactionStarted(INTERACTIONS.CLICK_SIGNUP_LINK)
          controller.wixCodeApi.user.promptLogin({ mode: "signup" });
          flowAPI.fedopsLogger.interactionEnded(INTERACTIONS.CLICK_SIGNUP_LINK)
        });
      }
      const forgotPasswordLink = $w("@forgotPasswordLink")[0];
      if (forgotPasswordLink) {
        forgotPasswordLink.onClick(() => {
          flowAPI.fedopsLogger.interactionStarted(INTERACTIONS.CLICK_FORGOT_PASSWORD)
          controller.wixCodeApi.user.promptForgotPassword();
          flowAPI.fedopsLogger.interactionEnded(INTERACTIONS.CLICK_FORGOT_PASSWORD)
        });
      }
    },
  };
}

const widgetViewerController = widgetScriptBuilder()
  .withCreateMethod(createWidget)
  .build();

export default ({
  controllerConfig,
  flowAPI,
}: {
  flowAPI: ControllerFlowAPI;
  controllerConfig: WidgetCreatorParams;
}) => {
  return widgetViewerController(controllerConfig, flowAPI);
};
